<template>
  <div>
    <b-modal
      v-model="modalShow"
      hide-header
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="title-tabs p-3">
        <b-row>
          <b-col md="6" class="text-left"> Product List </b-col>
          <b-col md="6" class="text-right">
            <span>
              Latest Update : {{ new Date() | moment($formatDate) }} ({{
                new Date() | moment($formatTime)
              }})
            </span>
          </b-col>
        </b-row>
      </div>
      <div class="filter-panel p-3">
        <div class="category-panel">
          <b-row>
            <b-col>
              <InputSelect
                title="Level Category 1"
                name="type"
                isRequired
                v-model="filter.ah1"
                v-bind:options="categoryList.category1"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 1)"
              />
            </b-col>
            <b-col>
              <InputSelect
                title="Level Category 2"
                name="type"
                isRequired
                v-model="filter.ah2"
                v-bind:options="categoryList.category2"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 2)"
              />
            </b-col>
            <b-col>
              <InputSelect
                title="Level Category 3"
                name="type"
                isRequired
                v-model="filter.ah3"
                v-bind:options="categoryList.category3"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 3)"
              />
            </b-col>
            <b-col>
              <InputSelect
                title="Level Category 4"
                name="type"
                isRequired
                v-model="filter.ah4"
                v-bind:options="categoryList.category4"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 4)"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="6">
              <b-input-group>
                <b-form-input
                  class="search-bar"
                  @keyup.enter="onSubmitFilter"
                  placeholder="Search Privilege Name, Promotion Code"
                  v-model="filter.search"
                >
                </b-form-input>
                <b-input-group-append is-text>
                  <b-iconstack
                    font-scale="2"
                    type="submit"
                    @click="onSubmitFilter"
                  >
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class="pb-3 pl-3 pr-3">
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            :fields="fieldsSelectProduct"
            :items="productList"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:head(id)="">
              <b-form-group class="cb-table">
                <b-form-checkbox
                  v-model="selectAllProduct"
                  @change="selectAllTable"
                ></b-form-checkbox>
              </b-form-group>
            </template>
            <template v-slot:cell(id)="data">
              <b-form-group class="cb-table">
                <b-form-checkbox
                  :value="data.item.id"
                  v-model="filter.productIdList"
                  @change="selectProductTable"
                ></b-form-checkbox>
              </b-form-group>
            </template>
            <template v-slot:cell(imge_url)="data">
              <div class="position-relative picture-text pic-table">
                <div
                  v-if="data.item.image_url"
                  class="square-box b-contain image"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.image_url + ')',
                  }"
                ></div>
                <div
                  v-else-if="data.item.imageUrl"
                  class="square-box b-contain image"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imageUrl + ')',
                  }"
                ></div>
                <div v-else>-</div>
              </div>
            </template>
            <template v-slot:cell(name)="data">
              <div class="text-left">
                <p class="m-0 text-black" v-if="data.item.name">
                  {{ data.item.name }}
                </p>
                <div>
                  {{ data.item.article_no }}
                  <span class="article-type">{{ data.item.article_type }}</span>
                </div>
              </div>
            </template>
            <template v-slot:cell(barcode)="data">
              <p class="m-0" v-if="data.item.barcode">
                {{ data.item.barcode }}
              </p>
              <p v-else class="m-0">-</p>
            </template>

            <template v-slot:cell(price)="data">
              <p class="m-0 price-text" v-if="data.item.price">
                {{ data.item.price | numeral("0,0.00") }}
              </p>
              <p v-else class="m-0 price-text">-</p>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col sm="4">
          <p class="mb-0 p-gray">
            Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
            {{
              filter.start + filter.length >= rows
                ? rows
                : filter.start + filter.length
            }}
            of {{ rows }} entries
          </p>
        </b-col>
        <b-col sm="8">
          <b-row>
            <b-col class="form-inline justify-content-end">
              <div>
                <b-pagination
                  v-model="filter.pageNo"
                  :total-rows="rows"
                  :per-page="filter.length"
                  @change="pagination"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  class="m-md-0"
                ></b-pagination>
              </div>
              <div class="ml-1">
                <b-form-select
                  v-model="filter.length"
                  @change="hanndleChangePerpage"
                  :options="pageOptions"
                ></b-form-select>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="submit-btn" @click.prevent="submitProduct"
              >Save</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
  },
  props: {},
  data() {
    return {
      modalShow: false,
      isBusy: false,
      fieldsSelectProduct: [
        {
          key: "id",
          label: "",
          class: "text-nowrap",
        },
        {
          key: "imge_url",
          label: "Image",
          class: "text-nowrap",
        },
        {
          key: "name",
          label: "Name/Code/Type",
          class: "text-nowrap",
        },
        {
          key: "barcode",
          label: "Product Code",
          class: "text-nowrap",
        },
        {
          key: "price",
          label: "Price",
          class: "text-nowrap",
        },
      ],
      productList: [],
      allListId: [],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      filter: {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: null,
        ah2: null,
        ah3: null,
        ah4: null,
        productIdList: [],
      },
      categoryList: {
        category1: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category2: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category3: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category4: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
      },
      selectAllProduct: false,
    };
  },
  methods: {
    async show(select) {
      this.filter.productIdList = select;
      this.modalShow = true;
      await this.getProductList();
      let allProductId = this.productList.map((el) => el.id);
      let checkAll = allProductId.filter((el) => select.includes(el));
      this.selectAllProduct =
        allProductId.length == checkAll.length ? true : false;
    },
    hide() {
      this.filter = {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: null,
        ah2: null,
        ah3: null,
        ah4: null,
        productIdList: [],
      };
      this.categoryList = {
        category1: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category2: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category3: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category4: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
      };
      this.selectAllProduct = false;
      this.modalShow = false;
    },
    onSubmitFilter() {
      this.filter.pageNo = 1;
      this.filter.start = 0;
      this.filter.length = 5;
      this.getProductList();
    },
    submitProduct() {
      let product = [];
      this.filter.productIdList.forEach((e, index) => {
        product.push({ id: e, point: 0 });
      });
      let productSort = product.sort(function (a, b) {
        var x = b["id"];
        var y = a["id"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
      this.$emit("submitProduct", productSort);
      this.hide();
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getProductList();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.length = value;
      this.getProductList();
    },
    async getProductList() {
      this.isBusy = true;
      this.filter.start = (this.filter.pageNo - 1) * this.filter.length;
      await this.$store.dispatch("getListProduct", this.filter);
      const data = this.$store.state.redeem.productList;
      this.productList = data.detail.product_list;
      this.rows = data.detail.total_product;
      this.allListId = data.detail.id_all;
      for (let i = 1; i <= 4; i++) {
        if (this.categoryList[`category${i}`].length == 1) {
          this.categoryList[`category${i}`] = data.detail[`list_ah${i}`]
            ? this.categoryList[`category${i}`].concat(
                data.detail[`list_ah${i}`]
              )
            : this.categoryList[`category${i}`];
        }
      }
      let allProductId = this.productList.map((el) => el.id);
      let checkAll = allProductId.filter((el) =>
        this.filter.productIdList.includes(el)
      );
      this.selectAllProduct =
        allProductId.length == checkAll.length ? true : false;
      this.isBusy = false;
    },
    clearCategory(id, index) {
      this.filter.categories.forEach((element, cindex) => {
        if (cindex > index) {
          this.categoryList[`category${cindex}`] = [
            {
              value: 0,
              text: "All",
            },
          ];
          this.filter.categories[cindex] = 0;
        } else if (cindex == index) {
          this.filter.categories[cindex] = 0;
        }
      });
    },
    onCateGoryChange(id, index) {
      this.clearCategory(id, index);
      this.getCategoryList(id, index + 1);
      this.filter.categories[index - 1] = id;
      this.getProductList();
    },
    getCategoryList(id, index) {
      this.axios.get(`/productinventory/category/${id}`).then((data) => {
        if (data.detail) {
          data.detail.category.forEach((l) => {
            this.categoryList[`category${index}`].push({
              value: l.categoryId,
              text: l.categoryName,
            });
          });
        } else {
          this.categoryList[`category${index}`] = [];
          this.categoryList[`category${index}`].push({
            value: 0,
            text: "All",
          });
          this.filter.categories[index] = 0;
        }
      });
    },
    selectAllTable(value) {
      if (value) {
        this.filter.productIdList.push(...this.productList.map((el) => el.id));
      } else {
        this.filter.productIdList = this.filter.productIdList.filter(
          (el) => !this.productList.map((el) => el.id).includes(el)
        );
      }
    },
    selectProductTable(value) {
      this.selectAllProduct =
        this.allListId.length == value.length ? true : false;
    },
    selectCategory(value, num) {
      this.filter[`ah${num}`] = value;
      for (let i = 1; i <= 4; i++) {
        if (i > num) {
          this.categoryList[`category${i}`] = [
            {
              ah_id: null,
              ah_name: "All",
            },
          ];
          this.filter[`ah${i}`] = null;
        }
      }
      this.getProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-search {
  color: #404040 !important;
  border: 1px solid #d8dbe0 !important;
  border-radius: 6px !important;
  padding: 9px 10px !important;
  height: 41px;
}
.search-input {
  height: 42.4px;
  width: 98%;
  border: 1px solid #d8dbe0;
  border-radius: 7px;
}
.btn-table-select {
  height: 42.4px;
}

.pic-box {
  padding-top: 23% !important;
}
.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .modal-body {
  padding: 0 !important;
}
.image-container {
  width: 230px !important;
}
.text-gray {
  color: #747474;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
::v-deep img {
  width: 230px !important;
  height: 230px !important;
}
</style>
